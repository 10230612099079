













import { Component, Vue } from "vue-property-decorator";
import usersWrapper from "@/components/knockoutWrappers/UsersWrapper.vue";
import events from "App/events";
import htmlEncoder from "Utilities/htmlencode-helper";

@Component({
  components: {
    usersWrapper,
  },
})
export default class UsersList extends Vue {
  userOnCreatedBinding: any = null;
  userOnCreatedFailedBinding: any = null;
  userOnCreatedAlreadyExistBinding: any = null;
  userOnUpdatedBinding: any = null;
  userOnRemovedBinding: any = null;
  userOnFailedBinding: any = null;

  mounted(): void {
    this.userOnCreatedBinding = events.userOnCreated.add((user: any) => {
      this.$store.dispatch("Snackbar/showSnackbar", {
        text: `User '${htmlEncoder.htmlEncode(user.name)}' has been <b>created</b> with the role of '${htmlEncoder.htmlEncode(user.role)}'`,
        color: "success",
      });
    });

    this.userOnCreatedFailedBinding = events.userOnCreatedFailed.add(() => {
      this.$store.dispatch("Snackbar/showSnackbar", {
        text: "Failed creating user",
      });
    });

    this.userOnCreatedAlreadyExistBinding = events.userOnCreatedAlreadyExist.add(() => {
      this.$store.dispatch("Snackbar/showSnackbar", {
        text: "User already exists",
      });
    });

    this.userOnUpdatedBinding = events.userOnUpdated.add((user: any) => {
      this.$store.dispatch("Snackbar/showSnackbar", {
        text: `User '${htmlEncoder.htmlEncode(user.name)}' has been <b>updated</b>`,
        color: "success",
      });
    });

    this.userOnRemovedBinding = events.userOnRemoved.add((user: any) => {
      this.$store.dispatch("Snackbar/showSnackbar", {
        text: `User '${htmlEncoder.htmlEncode(user.name)}' has been <b>deleted</b></i>`,
        color: "success",
      });
    });

    this.userOnFailedBinding = events.userOnFailed.add((error: any) => {
      this.$store.dispatch("Snackbar/showSnackbar", {
        text: error.responseJSON.message || "An error occurred",
      });
    });
  }

  beforeDestroy(): void {
    this.userOnCreatedBinding.detach();
    this.userOnCreatedFailedBinding.detach();
    this.userOnUpdatedBinding.detach();
    this.userOnRemovedBinding.detach();
    this.userOnFailedBinding.detach();
  }
}
