import { render, staticRenderFns } from "./UsersWrapper.vue?vue&type=template&id=7c7aee1b&scoped=true&"
import script from "./UsersWrapper.vue?vue&type=script&lang=ts&"
export * from "./UsersWrapper.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c7aee1b",
  null
  
)

export default component.exports